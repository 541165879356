<template>
  <div class="main-div">
    <nav class="navbar navbar-expand-lg navbar-light bg-primary">
      <div class="container">
        <a class="navbar-brand p-0" href="/">
<!--          <img src="@/assets/logo.png" width="100" alt="Logo"/>-->
          <img src="@/assets/logo_allcare.png" width="180" alt="Logo"/>

        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <i class="fa fa-navicon" style="    font-size: 30px;"></i>
        </button>
        <div id="navbarSupportedContent" class="collapse navbar-collapse">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0 text-white">
            <li class="nav-item">
              <a href="/#about-us" class="btn me-2">
                {{ $t("About us") }}
              </a>
            </li>
            <li class="nav-item">
              <a href="/#shift-rates" class="btn me-2">
                {{ $t("Shift Rates") }}
              </a>
            </li>
            <li class="nav-item pe-2">
              <a href="/#get-in-touch" class="btn me-2">
                {{ $t("Get In Touch") }}
              </a>
            </li>
            <li class="">
              <router-link v-if="hasPortalAccess" :to="portalLink" class="btn btn-default text-white me-2 fw-bold mb-2">
                {{ $t("Portal") }}
              </router-link>
            </li>
            <li class="">
              <router-link v-if="!$store.state.user.loggedIn" to="/login" class="btn btn-default text-white fw-bold mb-2">
                {{ $t("Log In") }}
              </router-link>
              <base-button v-else class="text-white mb-2" @click="$store.dispatch('signOut')">
                {{ $t("Log Out") }}
              </base-button>
            </li>
            <li class="">
              <div class="btn-group language-changer">
                <button type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="fa fa-globe"></i> {{ locale.label }}
                </button>
                <ul class="dropdown-menu">
                  <li class="text-center"><button class="dropdown-item" :class="{'active' : locale.value === 'en'}" @click="setLocale('en')" type="button">En</button></li>
                  <li><hr class="dropdown-divider" style="margin: 0px;"></li>
                  <li class="text-center"><button @click="setLocale('el')" class="dropdown-item" type="button">Ελ</button></li>


                </ul>
              </div>
<!--              <multiselect-->
<!--                  v-model="locale"-->
<!--                  class="custom-transition-form"-->
<!--                  label="label"-->
<!--                  :show-labels="false"-->
<!--                  track-by="value"-->
<!--                  placeholder="Language"-->
<!--                  :options="locales"-->
<!--                  :searchable="false"-->
<!--              >-->
<!--              </multiselect>-->
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <!-- Navbar -->
    <div>
      <router-view v-slot="{ Component }">
          <component :is="Component" :key="$route.fullPath"></component>
      </router-view>
    </div>

    <footer class="py-5 bg-gradient-gray">
      <div class="container">
        <div class="row align-items-start  justify-content-xl-between">
<!--          <div class="col-md-3 text-white"></div>-->
<!--          <div class="col-md-3 text-white"></div>-->
          <div class="col-md-6 text-white text-md-start">

            <h3 class="text-white">{{ $t("Info") }}</h3>
            <a class="link-light text-white-50  text-decoration-none fw-bold" href="/#get-in-touch">{{ $t("Get In Touch") }}</a><br>
            <a class="link-light text-white-50  text-decoration-none fw-bold" href="/#about-us">{{ $t("About us") }}</a><br>
           </div>
          <div class="col-md-6 text-white text-md-end pt-xs-5">
            <h3 class="text-white">{{ $t("Get In Touch") }}</h3>
            <div>
              <div class="text-white-50 fw-bold"> +357 94 099400</div>
              <div class="text-white-50 fw-bold"> info@allcare.com.cy</div>
            </div>

          </div>
<!--          <div class="col-xl-12 text-end pt-3">-->
<!--            <img src="@/assets/images/ssl-small.png" width="100" class="d-block float-end" alt="..."> <br>-->
<!--          </div><br>-->
          <div class="col-xl-12 text-white text-md-start pt-3">
            <div class="copyright text-md-right text-xl-left fw-bolder float-md-start">
              &copy; {{ year }} AllCare
</div>
            <img src="@/assets/images/ssl-small.png" width="100" class="d-block float-end" alt="..."> <br>


</div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
import packageData from "../../package.json"
import BaseButton from "@/components/BaseButton"

export default {
  name: "top-layout",
  components: {
    BaseButton,
    // VueMultiselect
  },
  data() {
    return {
      locale: "",
      version: null,
      locales: [
        {label: "EN", value: "en"},
        {label: "Ελ", value: "el"},
        // {label: 'Russian', value: 'ru'}
      ],
      year: new Date().getFullYear(),
      showMenu: false,
    }
  },
  computed: {
    portalLink(){
      let role = this.$store.state.user.claims.role ?? null
      if (role === null){
        return ""
      }

      switch (role){
        case "SYSTEM_USER":
          return "/dashboard"
        case "EMPLOYEE":
          return "/find-a-shift"
        case "CLIENT":
          return "/requests"
      }

      return ""
    },
    hasPortalAccess(){
      return this.$store.state.user.claims !== null && this.$store.state.user.claims.hasOwnProperty("role")
    }
  },
  watch: {
    locale(newVal) {
      console.log("locale changed", newVal)
    },
  },
  mounted() {
    this.version = packageData.version
    this.setLocaleLabel()


    // console.log("i18n.LanguageHelpers.GetAppLanguages()", this.$i18n.)

  },
  methods: {
    setLocaleLabel(){
      let localeSelected = this.locales.find( l => l.value === this.$store.state.locale)

      if(localeSelected){
        this.locale = localeSelected
      }
    },
    setLocale (locale) {
      // this.$store.state.locale = value
      // console.log(locale.toLowerCase())
      this.$i18n.locale = locale.toLowerCase()
      this.$store.dispatch("setLocale", locale.toLowerCase())
      // without this line it didn't work
      this.$moment.locale(locale.toLowerCase())
      this.setLocaleLabel()
    },
  },
}
</script>
<style>
.locale-changer select {
  /*background: transparent;*/
  border: none;
  /*color: white;*/
}
.locale-changer{
  margin-left: auto !important;
  cursor: pointer;
  padding-top: 10px;}

.locale-changer select:focus-visible {
  outline: none;
}
.multiselect.small-select .multiselect__select {
  padding: 4px 6px;
  height: 30px;
  width: 30px;
}

.multiselect.small-select .multiselect__tags {
  max-height: 30px;
  padding: 5px 27px 0 4px;
  min-height: 30px;
  background: unset;
}


@media(min-width: 767px)  {
  .mb-only{
    display: none !important;
  }
}

ul.dropdown-menu.show {
  padding: 0;
}
a.active.router-link-exact-active.nav-link.dropdown-item {
  color: white !important;
}
ul.dropdown-menu.show {
  border-radius: 0px 0 5px 5px;
}

.navbar .nav-item a {
  color: #fff!important;
  font-weight: 500;
  font-size: larger;
  padding: 8px;
}

.navbar .nav-item a.active.router-link-exact-active.nav-link, .navbar .nav-item a:hover {
  background-image: linear-gradient(90deg,#2c3e50, #364656 50%,#fff 0);
  background-size: 200% 100%;
  background-position: -100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all .3s ease-in-out;
  text-shadow: 0 0 2px #f9f9f9;
}

</style>