<template>
  <div>
    <base-header class="pb-6 pb-8 pt-8 pt-md-5">
      <!-- Card stats -->
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <div class="col-xl-8 order-xl-1">
            <card shadow type="secondary">
              <template #header>
                <div class="border-0">
                  <div class="row align-items-center">
                    <div class="col-8 text-start">
                      <h5 v-if="!isUpdate" class="mb-0">{{ $t("Add new System User") }}</h5>
                      <h5 v-else class="mb-0">{{ $t("Update System User") }}</h5>
                    </div>
<!--                    <div class="col-4 text-end btn-sm">-->
<!--                      <base-button @click="deleteUser" size="sm" type="danger" >Remove</base-button>-->
<!--                    </div>-->
                  </div>
                </div>
              </template>
              <form>
                <div class="ps-lg-4">
                  <div class="row">
                    <div class="col-lg-6">
                      <base-input
                          v-model="model.email"
                          alternative=""
                          :label="$t('Email')"
                          :placeholder="$t('Email')"
                          input-classes="form-control-alternative"
                      />
                    </div>
                    <div v-if="!isUpdate" class="col-lg-6">
                      <base-input
                          v-model="model.password"
                          alternative=""
                          :label="$t('Password')"
                          :placeholder="$t('Password')"
                          input-classes="form-control-alternative"
                      />
                    </div>
                    <div class="col-lg-6 text-start">
                      <div class="form-group has-label">
                      <label class="form-control-label ">{{ $t("Preferred language") }} </label>
                      <multiselect
                          v-model="model.lang"
                          :options="['en','el']"
                          :placeholder="$t('Select language')"
                          :show-labels="false"
                          required
                          class=""
                      >
                        <template #singleLabel="{ option }">{{ $t(option) }}</template>
                        <template #option="props" >
                          {{ $t(props.option) }}
                        </template>
                      </multiselect>
</div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <base-input
                          v-model="model.firstName"
                          alternative=""
                          :label="$t('First name')"
                          :placeholder="$t('First name')"
                          input-classes="form-control-alternative"
                      />
                    </div>
                    <div class="col-lg-6">
                      <base-input
                          v-model="model.lastName"
                          alternative=""
                          :label="$t('Last name')"
                          :placeholder="$t('Last name')"
                          input-classes="form-control-alternative"
                      />
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group has-label">
                        <label class="form-control-label">{{ $t("Receive request updates") }} </label>
                        <multiselect
                            v-model="model.receiveRequestUpdates"
                            :options="['true', 'false']"
                            :preselect-first="true"
                            :show-labels="false"
                            tagPosition="bottom"
                            required
                            class=""
                        >
                          <template v-slot:singleLabel="{ option }" >{{ $t(option.toString()) }}</template>
                          <template #option="props" >
                            {{ $t(props.option.toString()) }}
                          </template>
                        </multiselect>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group has-label">
                      <label class="form-control-label">{{ $t("Status") }} </label>
                      <multiselect
                          v-model="model.status"
                          :options="['ACTIVE', 'DISABLED']"
                          :placeholder="$t('Select status')"
                          :show-labels="false"
                          required
                          class=""
                      >
                        <template v-slot:singleLabel="{ option }" >{{ $t(option) }}</template>

                      </multiselect>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 text-end">
                  <base-button v-if="!isUpdate" class="me-2" outline type="info" @click="clearForm">{{ $t("Reset") }}</base-button>
                  <submit-button :alert-message="errorMessage" :state="loadingProcess" @clicked="submitUser">{{ $t(isUpdate ? 'Update' : 'Submit') }}</submit-button>
                </div>
              </form>
            </card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FirebaseFunctionsHelper from "@/helpers/firebaseFunctionsHelper"
import BaseButton from "@/components/BaseButton"
export default {
  name: "newSystemUser",
  components: {BaseButton},
  data() {
    return {
      isUpdate: false,
      errorMessage: "",
      loadingProcess: "",
      loadingMessage: "",
      dbUser: null,
      model: {
        lang: "en",
        email: "",
        password: "",
        firstName: "",
        lastName: "",
        receiveRequestUpdates: "true",
        status: "ACTIVE"
      },
    }
  },
  async mounted() {
    if (this.$route.params.hasOwnProperty("id")) {
      this.isUpdate = true
      await this.getSystemUser()
    }
  },
  methods: {
    async getSystemUser() {
      await this.$store.dispatch("fetchSystemUser", this.$route.params.id).then( user => {
        this.dbUser = JSON.parse(JSON.stringify(user))
        this.model = user
        this.model.receiveRequestUpdates = this.model.receiveRequestUpdates.toString()
      }).catch( error => {
        console.log(error)
      })
    },
    async submitUser() {
      let self = this

      let payload = {
        firstName: this.model.firstName,
        lastName: this.model.lastName,
        status: this.model.status,
        lang: this.model.lang,
        receiveRequestUpdates: this.model.receiveRequestUpdates,
        email: this.model.email
      }

      if (this.isUpdate && this.dbUser !== null){
        payload["systemUserId"] = this.$route.params.id

      } else {
        payload["password"] = this.model.password
      }

console.log("payload", payload)
      this.loadingProcess = "processing"
      FirebaseFunctionsHelper.callFunction(
          this.isUpdate ? "system-updateUser" : "system-createUser",
        // eslint-disable-next-line no-undef
        payload
      )
        .then((result) => {
          // eslint-disable-next-line no-prototype-builtins
          if (result != null && result.hasOwnProperty("message")) {
            self.loadingProcess = "failed"
            self.errorMessage = result.message
            return
          }
          this.loadingProcess = "done"
          this.$router.push({ path: "/systemUsers" })
        })
        .catch((error) => {
          self.loadingProcess = "failed"
          self.errorMessage = error.message
          console.log("submitUser error", error)
        })

      // setTimeout(() => {
      //   this.loadingProcess = "failed";
      //   this.loadingMessage = "There was an error.";
      //   //Add Tokens
      // }, 2000);
    },
    clearForm() {
      this.loadingProcess = ""
      if (this.dbUser){
        this.model = this.dbUser
      } else {
        this.model = {
          email: "",
          firstName: "",
          lastName: "",
          status: ""
        }
      }
    },
  },
}
</script>
<style scoped>
.form-group.has-label {
  padding-top: 0.5rem !important;
}
</style>
