<template>
  <div>
  <div class="btn-group" role="group" aria-label="Action buttons">
    <button v-if="canVerify" type="button" class="btn btn-success" @click="verifyRequest">{{ $t("Verify") }}</button>
    <button v-if="canCancel" type="button" class="btn btn-danger" @click="cancelModal = true">{{ $t("Cancel") }}</button>
    <button v-if="canCancelClaim" type="button" @click="cancelClaimModal = true" class="btn btn-dark">{{ $t("Cancel Claim") }}</button>
    <button v-if="canAssign" type="button" @click="tryClaim" class="btn btn-primary">
      {{ $t(userRole !== "SYSTEM_USER" ? "Claim" : "Assign") }}
    </button>
    <button v-if="canNoShow" type="button" class="btn btn-danger" @click="noShowModal = true">{{ $t("Mark as no-show") }}</button>
    <button v-if="canDownloadPaymentNote" type="button" class="btn btn-primary" @click="downloadPaymentNote(requestData.id, requestData.refNumber, requestData.clientId)"> <i class="fa fa-download"></i> {{ $t("Payment note") }}</button>
    <button v-if="canReviewRequest" type="button" class="btn btn-info" @click="showReviewRequest = true">
      <i class="fa fa-ranking-star"></i> {{ $t("Add Review") }}
    </button>
    <button v-if="hasReview" type="button" class="btn btn-info" @click="getAndShowReview()">
      <i class="fa fa-ranking-star"></i> {{ $t("Check Review") }}
    </button>
</div>

    <modal id="noShowModal" :show="noShowModal" @close="noShowModal = false">
      <template v-slot:header>
        <h5 class="modal-title">
          {{ "Mark as no-show" }}
        </h5>
      </template>
      <div>
        <base-input
            :label="$t('No show reason')"
            alternative=""
            :error="v$.reason.$errors.map( err => { return err.$message })[0]"
        >
                <textarea
                    v-model="reason"
                    rows="4"
                    class="form-control form-control-alternative"
                    placeholder="The reason of not showing up"
                >
                </textarea>
        </base-input>
        <div class="pl-lg-6">
          <div class="row">
            <div v-if="requestData !== null && ['VERIFIED', 'CLAIMED'].includes(requestData.status) && requestData.mDeadline < today" class="col-lg-12 text-start p-3">
              <b class="text-danger">
                {{ $t("By doing this") }} {{requestData.employeeName }} {{ $t("will receive a penalty of") }}
                {{ requestData.hasOwnProperty("cost") ? (requestData.cost.companyCommission/100) : 0 }}€
              </b>
            </div>
          </div>
        </div>
      </div>
      <template v-slot:footer>
        <submit-button
            :alert-message="errorMessage"
            :state="loadingProcess"
            @clicked="markNoShow()"
        >
          {{ $t("Submit") }}
        </submit-button>
      </template>
    </modal>

    <modal id="cancellationModal" :show="cancelModal" @close="cancelModal = false">
      <template v-slot:header>
        <h5 class="modal-title">
          {{ $t("Request Cancellation") }}
        </h5>
      </template>
      <div>
        <div class="pl-lg-6">
          <div class="row">
            <div class="col-lg-12">
              <base-input
                  :label="$t('Cancellation reason')"
                  alternative=""
                  :error="v$.reason.$errors.map( err => { return err.$message })[0]"
                 >
                <textarea
                    v-model="reason"
                    rows="4"
                    class="form-control form-control-alternative"
                    placeholder="The reason of cancellation">
                </textarea>
              </base-input>

            </div>
<!--            <div class="col-lg-12 text-start">-->
<!--              <label class="form-label">{{ $t("Who requested the cancellation?") }}</label>-->
<!--              <multiselect-->
<!--                  v-model="requester"-->
<!--                  :options="[{key:'EMPLOYEE', value: 'Employee'}, {key:'CLIENT', value: 'Client'}]"-->
<!--                  placeholder="Select"-->
<!--                  :show-labels="false"-->
<!--                  label="value"-->
<!--                  required-->
<!--                  track-by="key"-->
<!--                  class=""-->
<!--              ></multiselect>-->
<!--              <div v-if="v$.requester.$invalid" class="text-danger invalid-feedback" style="display: block;">-->
<!--                {{ v$.requester.$errors.map( err => { return err.$message })[0] }}-->
<!--              </div>-->
<!--            </div>-->
<!--            <div v-if="requester != null && requestData !== null && ['VERIFIED', 'CLAIMED'].includes(requestData.status) && requestData.mDeadline < today" class="col-lg-12 text-start p-3">-->

<!--              <b v-if="requester.key === 'EMPLOYEE' " class="text-danger">-->
<!--                By doing this {{requestData.employeeName }} will receive a penalty of-->
<!--                {{ requestData.hasOwnProperty("cost") ? (requestData.cost.totalCost/100)*( $store.state.settings.employeePenaltyPercent)/100 : 0 }}€-->

<!--              </b>-->
<!--            </div>-->
          </div>
        </div>
      </div>
      <template v-slot:footer>
        <submit-button
            :alert-message="errorMessage"
            :state="loadingProcess"
            @clicked="cancelRequest()"
        >
          {{ $t("Submit") }}
        </submit-button>
      </template>
    </modal>
    <review-modal :has-review="hasReview" :loading-process="loadingProcess" :error-message="errorMessage" :show-review-request="showReviewRequest" :review-model="reviewModel" @close-modal="showReviewRequest = false" @submit-review="submitReview"></review-modal>
    <modal id="claimCancellationModal" :show="cancelClaimModal" @close="cancelClaimModal = false">
      <template v-slot:header>
        <h5 class="modal-title">
          {{ $t("Request Claim Cancellation") }}
        </h5>
      </template>
      <div>
        <div class="pl-lg-6">
          <div class="row">
            <div class="col-lg-12">
              <base-input
                  :label="$t('Cancellation reason')"
                  alternative=""
                  :error="v$.reason.$errors.map( err => { return err.$message })[0]"
              >
                <textarea
                    v-model="reason"
                    rows="4"
                    class="form-control form-control-alternative"
                    placeholder="The reason of cancellation">
                </textarea>
              </base-input>
            </div>
            <div v-if="requestData.hasOwnProperty('mDeadline')" class="col-lg-12 text-start p-3">
              <b v-if="requestData.mDeadline.toDate() <= today && userRole !== 'EMPLOYEE'" class="text-danger">
                {{ $t("By doing this") }} {{ requestData.employeeName }} {{ $t("will receive a penalty of") }}
                {{ requestData.hasOwnProperty("cost") ? (requestData.cost.companyCommission)/100 : "" }}€
              </b>
              <b v-else-if="requestData.mDeadline.toDate() <= today && userRole === 'EMPLOYEE'" class="text-danger">
                {{ $t("You are about to cancel your claim after the shift deadline.") }} <br>
                {{ $t("By doing this") }} {{ $t("you will receive a penalty of") }}
                {{ requestData.hasOwnProperty("cost") ? (requestData.cost.companyCommission)/100 : "" }}€
              </b>
              <b v-else-if="userRole !== 'EMPLOYEE'" class="text-success">
                {{ $t("The request will be switch to Verified in order to be available for other Partners") }}
              </b>
            </div>

          </div>
        </div>
      </div>
      <template v-slot:footer>
        <submit-button
            :alert-message="errorMessage"
            :state="loadingProcess"
            @clicked="cancelClaimRequest()"
        >
          {{ $t("Submit") }}
        </submit-button>
      </template>
    </modal>
    <modal id="assignModal" :show="assignModalBool" @close="assignModalBool = false">
      <template v-slot:header>
        <h5 class="modal-title">
          {{ userRole === 'SYSTEM_USER' ? $t("Assign Request") : $t("Claim Shift") }}
        </h5>
      </template>
      <div>
        <div class="pl-lg-6">
          <div class="row">
            <div v-if="userRole === 'SYSTEM_USER'" class="col-lg-12">
              <div class="form-group has-label" :class="{'has-danger': v$.assignEmployeeId.$error }">
              <label class="form-label">{{ $t("Select employee to assign request") }}</label>
              <multiselect
                  v-model="assignEmployeeId"
                  :show-labels="false"
                  label="name"
                  track-by="id"
                  :placeholder="$t('Select employee')"
                  :options="employees"
              >
              </multiselect>
              <div v-if="v$.assignEmployeeId.$invalid" class="text-danger invalid-feedback" style="display: block;">
                {{ v$.assignEmployeeId.$errors.map( err => { return err.$message })[0] }}
              </div>
                </div>
            </div>
            <div v-else class="col-lg-12 text-start">
              <span> {{ $t("Are you sure you want to claim the following shift?") }}</span>
              <table class="table">
                <tr>
                  <th>{{ $t("Position") }}</th>
                  <td>{{ requestData.position.name }}</td>
                </tr>
                <tr>
                  <th>{{ $t("Shift date") }}</th>
                  <td>{{ requestData.hasOwnProperty('mStartDate') ? requestData.mStartDate.format("dddd, DD MMM YYYY") : '' }}</td>
                </tr>
                <tr>
                  <th>{{ $t("Shift time") }}</th>
                  <td>{{ requestData.hasOwnProperty('mStartDate') ? requestData.mStartDate.format("HH:mm") : '' }} - {{requestData.hasOwnProperty('mEndDate') ? requestData.mEndDate.format("HH:mm") : '' }}</td>
                </tr>
                <tr>
                  <th>{{ $t("Shift Price") }}</th>
                  <td>{{ getValueOrDefault(requestData.cost ?? {}, 'totalCost', '' )/100+'€' ?? '' }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <template v-slot:footer>
        <submit-button
            :alert-message="errorMessage"
            :state="loadingProcess"
            @clicked="assignRequest()"
        >
          {{ $t("Submit") }}
        </submit-button>
      </template>
    </modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal"
import useVuelidate from "@vuelidate/core"
import {helpers, required} from "@vuelidate/validators"
import FirebaseFunctionsHelper from "@/helpers/firebaseFunctionsHelper"
import {auth} from "@/main"
import {getAnalytics, logEvent} from "firebase/analytics"
import BaseInput from "@/components/BaseInput.vue"
import SubmitButton from "@/components/SubmitButton.vue"
import ReviewModal from "@/views/Modals/ReviewModal.vue"

const arrayQuestionsValidator = (value) => {
  debugger
  for (let i = 0; i < value.length; i++) {
    if (!value[i].hasOwnProperty("value")) {
      return false
    }
  }

  console.log("arrayQuestionsValidator", value)
  return Array.isArray(value)
}
export default {
  name: "ActionButtons",
  components: {ReviewModal, SubmitButton, BaseInput, Modal},
  props: {
    requestData: {
      type: Object,
      description: "requestData",
    },
  },
  emits: ["fetch", "getRequest"],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      reviewModel: {
        score: null,
        questions: [
          {
            question: "Q1",
            order: 1,
            percentageScore: 15,
          },
          {
            question: "Q2",
            order: 2,
            percentageScore: 15
          },
          {
            question: "Q3",
            order: 3,
            percentageScore: 15
          },
          {
            question: "Q4",
            order: 4,
            percentageScore: 15
          },
          {
            question: "Q5",
            order: 5,
            percentageScore: 40
          }
        ],
        comment: ""
      },
      showReviewRequest: false,
      today: new Date(),
      employees: [],
      assignModalBool: false,
      assignEmployeeId: "",
      model: {},
      errorMessage: "",
      loadingProcess: "",
      cancelModal: false,
      requester: null,
      cancelClaimModal: false,
      noShowModal: false,
      reason: "",
      rating: 0
    }
  },
  validations () {
    return {
      // requester: {
      //   required
      // },
      reason: {
        required
      },
      assignEmployeeId: {
        required
      }
    }
  },
  computed: {
    userRole(){
      return this.$store.state.user.claims.role ?? null
    },
    hasReview(){
      if (this.userRole && !["CLIENT", "SYSTEM_USER"].includes(this.userRole)){
        return false
      }

      return this.requestData.hasOwnProperty("clientReviewId") && this.requestData.clientReviewId != null
    },
    canReviewRequest(){
      if (this.userRole && this.userRole !== "CLIENT"){
        return false
      }
      if (this.requestData.hasOwnProperty("mEndDate") && this.requestData.mEndDate.toDate() > this.today ){
        return false
      }
      if (this.requestData.hasOwnProperty("clientReviewId") && this.requestData.clientReviewId != null){
        return false
      }

      return true
    },
    canVerify(){
      return false

      if (this.userRole && this.userRole !== "SYSTEM_USER"){
        return false
      }
      if (this.requestData.hasOwnProperty("mStartDate") && this.requestData.mStartDate.toDate() < this.today ){
        return false
      }
      return this.requestData.status === "PENDING"
    },
    canCancel(){

      if (this.userRole && this.userRole === "EMPLOYEE"){
        return false
      }
      if (this.requestData.hasOwnProperty("mStartDate") && this.requestData.mStartDate.toDate() < this.today ){
        return !!(this.userRole && this.userRole === "SYSTEM_USER" && this.requestData.status === "VERIFIED")
      }
      return ["PENDING", "VERIFIED", "CLAIMED"].includes(this.requestData.status)
    },
    canCancelClaim(){
      if (this.requestData.hasOwnProperty("mStartDate")  && this.requestData.mStartDate.toDate() < this.today){
        return false
      }

      if (this.requestData.claimedBy !== this.$store.state.user.claims.user_id && this.userRole !== "SYSTEM_USER"){
        return false
      }
      return ["CLAIMED"].includes(this.requestData.status)
    },
    canAssign(){
      if (this.userRole === "CLIENT"){
        return false
      }
      if (this.requestData.hasOwnProperty("mStartDate")  && this.requestData.mStartDate.toDate() < this.today ){
        return false
      }
      return this.requestData.status === "VERIFIED"
    },
    canDownloadPaymentNote(){
      if (!["FINISHED", "CLAIMED"].includes(this.requestData.status)){
        return false
      }

      if (this.userRole === "SYSTEM_USER"){
        return true
      }

      if (this.userRole === "CLIENT" && this.requestData.clientId === this.$store.state.user.claims.clientId){
        return true
      }

      return this.userRole === "EMPLOYEE" && this.requestData.claimedBy === this.$store.state.user.claims.user_id
    },
    canNoShow(){
      if (this.requestData.status !== "CLAIMED" && (["CLIENT"].includes(this.userRole))){
        return false
      }
      if (!["FINISHED", "CLAIMED"].includes(this.requestData.status)){
        return false
      }

      if (!["SYSTEM_USER", "CLIENT"].includes(this.userRole)){
        return false
      }

      return this.requestData.hasOwnProperty("mStartDate")  ? this.requestData.mStartDate.toDate() <= this.today : false
    }
  },
  watch: {
    requestData(newVal) {
      console.log("newVal", newVal)
      this.model = newVal
    },
  },
  methods: {
    async getAndShowReview(){
      await this.$store.dispatch("fetchClientRequestReview", {employeeId: this.requestData.claimedBy, reviewId: this.requestData.clientReviewId}).then((data) => {
        // console.log("fetchClientRequestReview", data)
        // this.employees = data.data
        this.reviewModel.comment = data.comment
        this.reviewModel.score = parseFloat(data.score).toFixed(2)
        this.reviewModel.questions = data.results.map(item => {
          return {
            ...item,        // Spread the existing properties of the object
            value: item.rating // Add the new property `score` with the value from `value`
          }
        })
      })
      this.showReviewRequest = true
    },
    async submitReview(){

      let invalid = false
      for (let i = 0; i < this.reviewModel.questions.length; i++){
        const question = this.reviewModel.questions[i]
        if (!question.hasOwnProperty("value") || question.value === "" || question.value === null){
          this.reviewModel.questions[i].isInvalid = true
          invalid = true
        }
      }


      if (invalid) {
        return
      }
      this.loadingProcess = "processing"
      const payload = {
        clientId: this.requestData.clientId,
        requestId: this.requestData.id,
        rating: this.reviewModel.questions.map(item => ({
          question: item.question,
          rating: parseInt(item.value)
        })),
        comment: this.reviewModel.comment
      }
      console.log("payload", payload)
      this.submit("review-client", payload)
    },
    async downloadPaymentNote(requestId, ref, clientId) {
      const axios = require("axios")
      const user = auth.currentUser
      if (user == null) {
        alert("sorry cont do this right now")
        return
      }

      const analytics = getAnalytics()
      logEvent(analytics, "click", {
        item_id: "download",
        page_title: this.$route.name
      })

      const token = await user.getIdToken()
      const functionUrl = FirebaseFunctionsHelper.getFunctionsUrl("download-paymentNote")
      const config = {
        method: "get",
        url: functionUrl+"?requestId=" + requestId + "&clientId=" + clientId,
        responseType: "blob",
        headers: {
          "Authorization": "Bearer " + token
        }
      }
      this.$swal.showLoading()
      const self = this
      axios(config)
          .then(function (response) {
            console.log(response)
            const href = URL.createObjectURL(response.data)

            // create "a" HTML element with href to file & click
            const link = document.createElement("a")
            link.href = href
            link.setAttribute("download", "Payment_Note_" + ref + ".pdf") //or any other extension
            document.body.appendChild(link)
            link.click()
            self.$swal.close()
            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link)
            URL.revokeObjectURL(href)
          })
          .catch(function (error) {
            self.$swal.close()
            console.log(error)
          })


    },
    tryClaim(){
      console.log("this.$store.state.user", this.$store.state.user.data.status)
      if (this.userRole === "EMPLOYEE" && this.$store.state.user.data.status !== "VERIFIED") {
        this.$swal.fire({
          title: "You cannot claim this shift",
          html: "In order to claim this shift you need to be Verified",
          icon:"warning",
          customClass:{
            confirmButton: "bg-primary"
          }
          // timer: 1000,
          // timerProgressBar: true
        })
        return
      }
      this.getEmployees()
      this.assignModalBool = true
    },
    getValueOrDefault(obj, prop, defaultValue){
      return Object.prototype.hasOwnProperty.call(obj, prop) ? (obj[prop] || defaultValue) : defaultValue
    },
    assignRequest(){
      let payload
      if (this.userRole === "SYSTEM_USER") {
        this.v$.$touch()

        if (this.v$.assignEmployeeId.$invalid) {
          console.log(this.v$.$errors)
          return
        }



        payload = {
          clientId: this.requestData.clientId,
          requestId: this.requestData.id,
          employeeId: this.assignEmployeeId.id,
        }
      } else {
        payload = {
          clientId: this.requestData.clientId,
          requestId: this.requestData.id,
        }
      }
      this.loadingProcess = "processing"
      console.log("assign", payload)
      this.submit("request-claim", payload)
    },
    verifyRequest(){
      this.$swal.fire({
        title: "Are you sure?",
        text: "You are about to Verify this request",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, verify!",
        confirmButtonColor: "green",
        cancelButtonColor: "#dc3545",
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          FirebaseFunctionsHelper.callFunction("request-verify",
              {
                clientId: this.requestData.clientId,
                requestId: this.requestData.id
              }
          )
              .then((result) => {
                console.log("res", result)
                if (result != null && result.hasOwnProperty("message")) {
                  this.$swal.fire({
                    icon: "error",
                    html: result.message,
                    title: "Something went wrong!",
                  })
                  return
                }
                // this.$parent.$parent.$parent.$parent.getRequest()
                this.$emit("getRequest")
                this.$swal.fire({
                  title: "Verified!",
                  html: "The request was verified.",
                  icon:"success",
                  timer: 1000,
                  timerProgressBar: true
                })

              }, this)
              .catch((error) => {
                console.error("submitUser error", error)
                this.$swal.fire({
                  icon: "error",
                  html: result.message,
                  title: "Something went wrong!",
                })
              })
        }
      })
    },
    cancelClaimRequest(){
      this.v$.$touch()

      if (this.v$.reason.$invalid) {
        console.log(this.v$.$errors)
        return
      }

      this.loadingProcess = "processing"

      let payload = {
        clientId: this.requestData.clientId,
        requestId: this.requestData.id,
        cancellationReason: this.reason,
      }
      console.log("cancel", payload)

      this.submit("request-cancelClaim", payload)
    },
    markNoShow(){

      this.v$.$touch()

      if (this.v$.reason.$invalid) {
        console.log(this.v$.$errors)
        return
      }

      this.loadingProcess = "processing"
      let payload = {
        clientId: this.requestData.clientId,
        requestId: this.requestData.id,
        reason: this.reason
      }

      this.submit("request-noShow", payload)
    },
    cancelRequest(){
      this.v$.$touch()

      if (this.v$.reason.$invalid) {
        console.log(this.v$.$errors)
        return
      }

      this.loadingProcess = "processing"

      let payload = {
        clientId: this.requestData.clientId,
        requestId: this.requestData.id,
        cancellationReason: this.reason,
      }
      console.log("cancel", payload)

      this.submit("request-cancel", payload)

    },
    submit(name, data){
      FirebaseFunctionsHelper.callFunction(name, data)
          .then((result) => {
            console.log("res", result)
            // eslint-disable-next-line no-prototype-builtins
            if (result != null && result.hasOwnProperty("message")) {
              console.log("result message", result.message)
              this.loadingProcess = "failed"
              this.errorMessage = result.message
              return
            }
            this.loadingProcess = "done"

            // if (this.userRole !== "SYSTEM_USER"){
            //   this.$parent.$parent.$parent.getRequest()
            // } else {
            //   this.$parent.$parent.$parent.$parent.getRequest()
            // }
            this.$emit("getRequest")
            this.cancelModal = false
            this.cancelClaimModal = false
            this.assignModalBool = false
            this.showReviewRequest = false
            this.noShowModal = false
          })
          .catch((error) => {
            this.loadingProcess = "failed"
            this.errorMessage = error.message
            console.log("submitUser error", error)
          })

    },
    async getEmployees() {
      if (this.userRole !== "SYSTEM_USER"){
        return
      }
      await this.$store.dispatch("fetchEmployees", {position: this.requestData.position.id, status: "VERIFIED" , limit: 150}).then((data) => {
        console.log("fetchEmployees", data)
        this.employees = data.data
      })
    },
  }
}
</script>

<style scoped>

</style>